<template>
  <div class="rootView">

    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 100%; height: 2.6rem;position: absolute;"
        src="../../../assets/news/news_pic.jpg"
        alt />
    </view>

    <!--面包屑-->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">-->
    <el-breadcrumb separator="》"
                   style="
        width: auto;
        height: 0.15rem;
        margin-left: 2.40rem;
        margin-top: 0.2rem;
      "
    >
      <el-breadcrumb-item :to="{ path: '/' }">
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          "
        >
          首页
        </text>

      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/news' }">
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          "
        >
          新闻资讯
        </text>

      </el-breadcrumb-item>

      <el-breadcrumb-item>
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #45A2FF
          "
        >
          产品资讯
        </text>

      </el-breadcrumb-item>
    </el-breadcrumb>

    <!--横线-->
    <view
      style="
        width: 14.4rem;
        height: 0.01rem;
        background: #CCCCCC;
        margin-left: 2.40rem;
        margin-top: 0.2rem;
      "
    />

    <!--日期-->
    <text
      style="
          width: auto;
          height: auto;

          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 0.32rem;

          margin-top: 0.19rem;
          margin-left: 2.4rem;

      ">
      2022.05.16
    </text>

    <!--标题-->
    <text
      style="
          width: 14.34rem;
          height: auto;

          font-size: 0.32rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;

          margin-top: 0.46rem;
          margin-left: 2.4rem;
          text-align: center;

      ">
      石家庄社区直饮水升级改造惠民工程启动

    </text>


   <!-- <el-image
      :src="require('../../../assets/home/product/product_clener_biaozhun.png')"
      fit="contain"
      style="
        margin-top: 0.4rem;
        margin-left: 6.6rem;
        width: 6.3rem;
          "
      lazy
    />-->

    <view class="videoPlayView">
     <!-- <VuePlayerVideo class="videoPlay" src="https://en-zo.dev/vue3-player-video.mp4">  网络方式
      </VuePlayerVideo>-->
      <!-- poster="/video/img_cover.png" //视频初始封面地址。可选-->
      <video class="videoPlay"
        :src="require('../../../assets/news/chanpin/detail-3/0328.mp4')"
        controls
        :poster="require('../../../assets/news/chanpin/chanpin-news-3.jpg')"
      ></video>
    </view>

    <el-image
      :src="require('../../../assets/news/chanpin/detail-3/d-3.jpg')"
         fit="contain"
         style="
             margin-top: 0.5rem;
             margin-left: 5.6rem;
             width: 8rem;
         "
    />




  </div>
</template>

<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  //import VuePlayerVideo from 'vue3-player-video'

  export default {
    name: "Chanpin_Detail_3",

    components: {
      //VuePlayerVideo
    },

    setup() {
      //const router = useRouter()


      return {}
    }

  }
</script>

<style lang="scss" scoped>
  @import '../../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/

    /*隐藏滚动条*/
    overflow-x: hidden;
    /*overflow-y: auto;*/

    border-radius: 0;
  }

  /*通用标题*/
  .itemTitle{
    width: auto;
    height: 0.7rem;
    margin-left: 2.4rem;
    position: relative;

    &_img{
      width: 2.29rem;
      height: 0.7rem;

      position:absolute;
      top: 0;
      left: 0rem;

    }

    &_txt{
      width: auto;
      height: 0.23rem;
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;

      position: absolute;
      left: 0.82rem;
      top: 0.14rem;
    }

  }

  /*标题图片栏*/
  .titleView{
    width: 100%;
    height: 2.60rem;
    /*background-color: red;*/
  }


  /*视频播放器*/
  .videoPlayView{
    width: 14.4rem;
    height: auto;

    margin-top: 0.8rem;
    margin-left: 2.40rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  .videoPlay{
    width: 8rem;
    height: auto;

  }


</style>
